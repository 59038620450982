import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import styled from 'styled-components';
import { stacked } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import ContactPhone from 'app/shared/modules/hdp/form/Contact/ContactPhone';
import IconVerified from 'images/verified.svg';
import SkeletonContactListedBy from 'app/shared/modules/skeleton/SkeletonContactListedBy';
import Text from 'app/shared/core/Text';
import UserAvatarImage from 'images/user-avatar.svg';
import numberUtils from 'app/shared/utils/numberUtils';
import dateUtils from 'app/shared/utils/dateUtils';
import './style.scss';
import type { RootReduxState } from 'app/shared/flux/reducers';

const StyledContactPhone = styled(ContactPhone)`
  ${stacked._1x};
  font-weight: 700;
`;

const InfoStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 80px;
`;

interface ContactListedByV2Props {
  onClickPhoneContact: () => void;
  isPopup: boolean;
  isExperiment: boolean;
}

const ContactListedByV2 = (props: ContactListedByV2Props) => {
  const { onClickPhoneContact, isPopup, isExperiment } = props;
  const listedBy = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing?.listedBy);
  const details = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing?.details);
  const trusted = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing?.trusted);
  const waitlisted = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing?.waitlisted);
  const isXlScreen = useSelector((state: RootReduxState) => state.app.device.screenWidth === 'xl');

  const getFormattedResponseRate = (resRate: string) => {
    return numberUtils.asPercent(Number(resRate).toFixed(0));
  };

  const getFormattedResponseTime = (resTime: string) => {
    return dateUtils.recencyTime(Date.now() - Number(resTime), new Date()).displayDiff;
  };

  // Begin render content:
  const logoUrl = listedBy?.logoUrlCdn || details?.logoUrlCdn;
  const inlineMessage = isXlScreen && !isPopup;
  if (!listedBy) {
    return <SkeletonContactListedBy />;
  }
  const displayName =
    listedBy.contactName && listedBy.contactName !== 'Leasing Agent' // default contact name
      ? listedBy.contactName
      : 'Contact Manager';

  let className = '';

  if (isExperiment) {
    className = isPopup ? 'ContactListedByV2-popup-exp' : 'ContactListedByV2-exp';
  } else if (isPopup) {
    className = 'ContactListedByV2-popup';
  }

  return (
    <div className={className}>
      <div className="ContactListedByV2">
        <div className="ContactListedBy-left">
          <div className={isExperiment ? 'ContactListedBy-info-exp' : 'ContactListedBy-info'}>
            <div className="ContactListBy-name-avatar-container">
              <div className="ContactListedBy-avatar-container">
                <img src={UserAvatarImage} alt="Avatar" className="ContactListedBy-avatar" />
              </div>
              <div className="ContactListedBy-details">
                <div className="ContactListedBy-name">
                  <Text
                    id="dialog-title"
                    className="ContactListedBy-name-text"
                    size="md"
                    htmlTag={inlineMessage ? 'h2' : undefined}
                  >
                    {inlineMessage ? 'Message ' : ''}
                    {displayName}
                  </Text>
                </div>
                {listedBy.companyName && <Text size="sm">{listedBy.companyName}</Text>}
                {trusted && (
                  <div className="ContactListedBy-verified">
                    <img src={IconVerified} className="ContactListedBy-verified-icon" />
                    Verified listing
                  </div>
                )}
                {!waitlisted && <StyledContactPhone className="contact-phone" onClick={onClickPhoneContact} />}
              </div>
            </div>
          </div>
          <InfoStatsContainer>
            {listedBy.responseRate && (
              <div className="LandlordInfo-detail">
                <Text size="sm">{'Response rate '}</Text>
                <Text size="sm" style={{ fontWeight: '700', color: colors['$hpx-blue-600'] }}>
                  {getFormattedResponseRate(listedBy.responseRate)}
                </Text>
              </div>
            )}
            {listedBy.responseTime && (
              <div className="LandlordInfo-detail">
                <Text size="sm">{'Response time '}</Text>
                <Text size="sm" style={{ fontWeight: '700', color: colors['$hpx-blue-600'] }}>
                  {getFormattedResponseTime(listedBy.responseTime)}
                </Text>
              </div>
            )}
            {listedBy.memberSince?.date && (
              <div className="LandlordInfo-detail">
                <Text size="sm">{'Member since '}</Text>
                <Text size="sm" style={{ fontWeight: '700', color: colors['$hpx-blue-600'] }}>
                  {dateUtils.formatDateToString(listedBy.memberSince.date, { skipDay: true })}
                </Text>
              </div>
            )}
          </InfoStatsContainer>
        </div>
        {logoUrl && (
          <div className="ContactListedBy-logo-cont">
            <div className={cx('ContactListedBy-logo', { 'ContactListedBy-logo-popup': isPopup })}>
              {logoUrl && <img className="ContactListedBy-listedby-logo" src={logoUrl} height="64" width="64" alt="" />}
            </div>
          </div>
        )}
      </div>
      {waitlisted && (
        <Fragment>
          <div className="ContactForm-waitlist-header">Join the waitlist</div>
          <div
            className={cx('ContactForm-waitlist-text', {
              'ContactForm-waitlist-text-popup': isPopup,
            })}
          >
            This listing is in high demand. Don&apos;t fret! Get on the waitlist and we&apos;ll notify you and send your
            contact information to the property once there&apos;s an opening. Happy house hunting!
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ContactListedByV2;
