// App
import styled from 'styled-components';
import { stacked } from 'app/shared/styles/_spacing';

// Base components
import CheckboxBase from 'app/shared/modules/MobileSRP/__BaseComponents__/Checkbox';

export const Container = styled.div`
  position: relative; // Needed to prevent weird CSS overscrolling issues w/ the checkbox
  padding-left: 6px;
`;

export const Checkbox = styled(CheckboxBase)`
  ${stacked._1x};
  display: flex;
`;
