/* eslint-enable */
// App
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// Types
import type { SimilarListingDetails } from 'app/types/similarListingDetails.type';
import type { RootReduxState } from 'app/shared/flux/reducers';

// Lodash
import map from 'lodash/map';
import compact from 'lodash/compact';

// Actions
import ContactActions from 'app/shared/flux/actions/ContactActions';
import { logError } from '@zg-rentals/log-error';

// Components
import ContactSimilarListingsCheckboxWrapper from 'app/shared/modules/hdp/form/Contact/ContactSimilarListingsV2/ContactSimilarListingsCheckboxWrapper';
// import Button from 'app/shared/core/Button';
import Button from 'app/shared/core/ButtonV2';

// Misc / utils
import { getSimilarListingType, getTrackingFunction } from 'app/shared/utils/clickstreamUtils';
import {
  TrackSimilarListingMultiContactMessageButtonClick,
  TrackSimilarListingMultiContactSubmit,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import { analyticsEvent } from 'app/client/universal-analytics';
import * as S from 'app/shared/modules/hdp/form/Contact/ContactSimilarListingsV2/styles';

const ContactSimilarListingsV2 = ({ similarListings }: { similarListings: Array<SimilarListingDetails> }) => {
  // Redux
  const dispatch = useDispatch();
  const cachedInquiry = useSelector((state: RootReduxState) => state.user.inquiryDataCache);
  const userToken = useSelector((state: RootReduxState) => state.user.userToken);

  const shownSimilarListings = similarListings.slice(0, 5);
  const initState = new Array(shownSimilarListings.length).fill(null);
  const [listingsToContact, setListingsToContact] = useState(initState);
  const [haveMessagesBeenSent, setHaveMessagesBeenSent] = useState(false);
  const [dynamicButtonText, setDynamicButtonText] = useState('Send messages');

  const handleCheckboxClick = (listing: SimilarListingDetails, idx: number, action: string) => {
    if (action === 'add') {
      const tempArr = listingsToContact.toSpliced(idx, 1, listing);
      setListingsToContact(tempArr);
    } else {
      const tempArr = listingsToContact.toSpliced(idx, 1, null);
      setListingsToContact(tempArr);
    }
  };

  const handleSendMessageButtonClick = async () => {
    const compactListingsToContact = compact(listingsToContact);
    const DEFAULT_INQUIRY_TEXT = "I'm interested in your property.";

    // Clickstream event 7276
    dispatch(
      analyticsEvent({
        newLaneEvent: TrackSimilarListingMultiContactMessageButtonClick(),
      }),
    );

    // Loop through each listing and submit a contact!
    for await (const listing of compactListingsToContact) {
      try {
        const rentalSubmitId = uuidv4();
        const listingType = getSimilarListingType(listing);
        await dispatch(
          ContactActions.submitContact({
            listing,
            inquiryParams: {
              alias: listing.aliasEncoded,
              userToken: userToken,
              name: cachedInquiry.name,
              email: cachedInquiry.email,
              phone: cachedInquiry.phone,
              text: DEFAULT_INQUIRY_TEXT,
            },
            rentalSubmitId,
          }),
        );

        // Clickstream event 5575, 5576, 5577 paid, free, paid-demoted
        dispatch(
          analyticsEvent({
            newLaneEvent: getTrackingFunction({ type: listingType })({
              triggerObject: 'home_details|post_contact_modal',
              triggerSource: 'button_to_send_message',
            }),
          }),
        );

        // Clickstream event 7283
        dispatch(
          analyticsEvent({
            newLaneEvent: TrackSimilarListingMultiContactSubmit({
              rentalSubmitId,
              similarListing: listing,
            }),
          }),
        );
      } catch (err) {
        logError({
          error: err,
          errorType: 'SimilarListingsContactError',
          context: 'Error submitting contact for similar listings in post contact modal',
        });
      }
    }

    // Set state that messages have been sent
    setHaveMessagesBeenSent(true);

    // Change button text to read messages sent
    setDynamicButtonText('Messages sent');
  };

  if (shownSimilarListings.length === 0) {
    return null;
  }

  const shouldButtonBeDisabled = haveMessagesBeenSent || compact(listingsToContact).length === 0;

  return (
    <article>
      <S.Divider />
      <S.ContactCtaArticle>
        <S.ContactTextHeading>Contact more properties</S.ContactTextHeading>
        <S.ContactTextBody>
          {"Let more properties know that you're interested. Select the properties and message them all at once."}
        </S.ContactTextBody>
        <Button onClick={handleSendMessageButtonClick} disabled={shouldButtonBeDisabled}>
          {dynamicButtonText}
        </Button>
      </S.ContactCtaArticle>
      <S.OuterScrollContainer>
        <S.ListContainer>
          {map(shownSimilarListings, (similarListing, idx) => {
            return (
              <S.ListItem key={`similar-listing-${idx}-${similarListing.aliasEncoded}`}>
                <ContactSimilarListingsCheckboxWrapper
                  listing={similarListing}
                  onClick={handleCheckboxClick}
                  idx={idx}
                />
                <S.ListingWrapper
                  key={similarListing.aliasEncoded}
                  listing={similarListing}
                  triggerLocation="home_details"
                  triggerObject="home_details_component|post_contact_modal"
                />
              </S.ListItem>
            );
          })}
        </S.ListContainer>
      </S.OuterScrollContainer>
    </article>
  );
};

ContactSimilarListingsV2.propTypes = {
  similarListings: PropTypes.array,
};

export default ContactSimilarListingsV2;
