/* eslint-disable react/prop-types */
import Text from 'app/shared/core/Text';
import type { TextSizeKeys } from 'app/shared/core/Text/styles';
import Linker from 'app/shared/modules/Linker';
import pathsManifest from 'app/shared/utils/pathsManifest';

interface DisclaimerProps {
  linkType?: string;
  tourProviderName?: string;
  size?: TextSizeKeys;
}

const Disclaimer = ({ size = 'tiny', linkType = 'inverted', tourProviderName = '' } = {} as DisclaimerProps) => {
  if (tourProviderName) {
    return (
      <Text size={size}>
        By contacting this property, you agree to our{' '}
        <Linker linkType={linkType} to={pathsManifest.rentalTermsOfUse} target="_blank">
          Terms of Use
        </Linker>
        . Visit our{' '}
        <Linker linkType={linkType} to={pathsManifest.privacy} target="_blank">
          Privacy Portal
        </Linker>{' '}
        for more information. When you click “Schedule tour”, we’ll send your information to {tourProviderName} the tour
        scheduler, who will work with the property manager to schedule your tour. You may receive notifications or
        reminders about your tour from the tour scheduler or the property manager.
      </Text>
    );
  }

  return (
    <Text size={size}>
      By contacting this property, you agree to our{' '}
      <Linker linkType={linkType} to={pathsManifest.rentalTermsOfUse} target="_blank">
        Terms of Use
      </Linker>
      . Visit our{' '}
      <Linker linkType={linkType} to={pathsManifest.privacy} target="_blank">
        Privacy Portal
      </Linker>{' '}
      for more information. When you click &quot;Send message&quot;, we&apos;ll send your inquiry to the property
      manager so they can reach out and answer your questions.
    </Text>
  );
};

export default Disclaimer;
