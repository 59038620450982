/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import ListingWrapperBase from 'app/shared/modules/listing-cards/ListingWrapper';
import { font } from 'app/shared/styles/_fontsize';
import { inline, inset, stacked } from 'app/shared/styles/_spacing';
import { viewports } from 'app/shared/styles/_breakpoints';

// Components
import ListingCardsSmallBase from 'app/shared/modules/listing-cards/ListingCard';

export const RecommendedText = styled.p`
  ${font.md};
  ${inset._1x};
`;

export const ContactCtaArticle = styled.article`
  ${inset._2x};
  text-align: left;
`;

export const ContactTextHeading = styled.h2`
  ${font.lg};
  ${stacked._2x};
  font-weight: bold;
`;

export const ContactTextBody = styled.p`
  ${font.md};
  ${stacked._2x};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors['$hpx-grey-100']};
  margin: auto;
  width: 60%;
  padding-top: 8px;
`;

// @ts-ignore
export const ListingWrapper = styled(ListingWrapperBase)`
  width: 100%;
`;

export const ListingCardsSmall = styled(ListingCardsSmallBase)`
  ${inline._2x};
`;

export const OuterScrollContainer = styled.article`
  ${inset._2x};
`;

export const ListContainer = styled.ul`
  /* padding: 24px 16px 0 16px; */
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: scroll;
  row-gap: 28px;
  max-height: calc(100vh - 250px);

  @media ${viewports['md-and-up']} {
    max-height: 450px;
  }
`;

export const ListItem = styled.li`
  ${inline._1x};
`;
