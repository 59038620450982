// Components
import { useState } from 'react';
import {
  Checkbox,
  Container,
} from 'app/shared/modules/hdp/form/Contact/ContactSimilarListingsV2/ContactSimilarListingsCheckboxWrapperStyles';

import type { SimilarListingDetails } from 'app/types/similarListingDetails.type';

import { useYieldCallback } from '@zillow/yield-callback';

interface ContactSimilarListingsCheckboxWrapperProps {
  listing: SimilarListingDetails;
  onClick: (listing: SimilarListingDetails, idx: number, action: string) => void;
  idx: number;
}
const ContactSimilarListingsCheckboxWrapper = ({
  listing,
  onClick,
  idx,
}: ContactSimilarListingsCheckboxWrapperProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxClick = useYieldCallback(() => {
    setIsChecked(!isChecked);
    onClick(listing, idx, !isChecked ? 'add' : 'subtract');
  }, [isChecked, listing, idx, onClick]);

  return (
    <Container>
      <Checkbox
        absolutePositionOverride={false}
        onChange={handleCheckboxClick}
        isChecked={isChecked}
        ariaLabel={'Use exact number of bedrooms'}
      >
        Select
      </Checkbox>
    </Container>
  );
};

export default ContactSimilarListingsCheckboxWrapper;
