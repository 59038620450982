// @ts-nocheck
/* eslint-enable */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import styled from 'styled-components';
import { useYieldCallback } from '@zillow/yield-callback';

import './style.scss';
import { trackForRentPhoneContact } from 'app/client/tracking';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';
import controller from './controller';
import numberUtils from 'app/shared/utils/numberUtils';
import Text from 'app/shared/core/Text';
import {
  TrackPhoneLeadForDemotedProperty,
  TrackPhoneLeadForFreeProperty,
  TrackPhoneLeadForPaidProperty,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const StyledBoldDiv = styled.div`
  font-weight: 700;
`;

type ContactPhoneProps = {
  className?: string;
  isPopUp?: boolean;
  onClick?: () => void;
};

const ContactPhone = ({ className, isPopUp, onClick = () => {} }: ContactPhoneProps) => {
  // Redux
  const dispatch = useDispatch();
  const deviceType = useSelector((state) => state.app.device.deviceType);
  const currentListing = useSelector((state) => state.currentListingDetails.currentListing);
  const {
    address,
    aliasEncoded,
    isApartmentBldg,
    isPaidMultifamily,
    isPaidAndNotDemoted,
    listedBy,
    maloneLotIdEncoded,
    trusted,
  } = currentListing;
  const { contactPhone } = listedBy;

  // React state
  const [isContactNumberVisible, setIsContactNumberVisible] = useState(false);

  const handleShowContactClick = () => {
    setIsContactNumberVisible(true);
  };

  const handlePhoneClick = useYieldCallback(() => {
    onClick();

    if (deviceType === 'mobile') {
      const triggerObject = isPopUp
        ? 'home_details_component|modal_contact_form'
        : 'home_details_component|inline_form';
      let ClickstreamTracking = () => {};
      let listingPaidStatus = null;

      if (isPaidMultifamily && isPaidAndNotDemoted) {
        listingPaidStatus = 'paid';
        ClickstreamTracking = TrackPhoneLeadForPaidProperty;
      } else if (isPaidMultifamily && !isPaidAndNotDemoted) {
        listingPaidStatus = 'paidDemoted';
        ClickstreamTracking = TrackPhoneLeadForDemotedProperty;
      } else {
        listingPaidStatus = 'free';
        ClickstreamTracking = TrackPhoneLeadForFreeProperty;
      }

      dispatch(
        analyticsEvent(gaEvents.LEAD_PHONE, {
          label: listingPaidStatus,
          newLaneEvent: ClickstreamTracking({ triggerObject }),
        }),
      );
      trackForRentPhoneContact({
        maloneLotIdEncoded,
        aliasEncoded,
        address,
        trusted,
        isApartmentBldg,
      });
    }
  });

  return (
    <div className={cx('ContactPhone', className)}>
      {controller.showAfterHoursPhone() ? (
        <div onClick={handleShowContactClick}>
          <StyledBoldDiv>
            <Text size="sm">After business hours - response may be delayed</Text>
          </StyledBoldDiv>
          {isContactNumberVisible ? (
            <Text size="sm" className="ContactPhone-listedby-phone-link">
              {numberUtils.getDisplayPhoneNumber(contactPhone)}
            </Text>
          ) : (
            <Text size="sm" className="ContactPhone-show-phone">
              Click for phone
            </Text>
          )}
        </div>
      ) : (
        <div className="ContactPhone-listedby-phone">
          <a className="ContactPhone-listedby-phone-link" href={'tel://' + contactPhone} onClick={handlePhoneClick}>
            <Text size="sm">{numberUtils.getDisplayPhoneNumber(contactPhone)}</Text>
          </a>
        </div>
      )}
    </div>
  );
};

export default ContactPhone;
